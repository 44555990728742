import * as React from 'react';
import { BooleanField, ChipField, DateField, EmailField, ReferenceArrayField, Show, SimpleShowLayout, SingleFieldList, TextField, useRecordContext } from 'react-admin';

const UserTitle = () => {
  const record = useRecordContext();
  return (
    <span>User {record ? `"${record.firstName} ${record.lastName}"` : ''}</span>
  );
};

const UserShow = props => (
  <Show {...props} title={<UserTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <EmailField source="email" />
      <DateField source="dateOfBirth" options={{ timeZone: 'UTC'}} />
      <DateField source="firstSeen" options={{ timeZone: 'UTC'}} />
      <DateField showTime source="lastLoggedIn" options={{ timeZone: 'UTC'}} />
      <TextField label="AA Smartfuel card" source="smartFuelCard" />
      <TextField label="GAS Instant Discounts card" source="triquestraLoyaltyCard" />
      <ReferenceArrayField label="Favourites" reference="sites" source="site_id">
        <SingleFieldList>
          <ChipField source="siteName" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField source="fcmToken" />
      <TextField source="cognitoId" />
      <TextField source="referralCode" />
      <BooleanField looseValue source="isActive" />
      <BooleanField looseValue source="allowTransNotif" />
      <BooleanField looseValue source="allowPromoNotif" />
    </SimpleShowLayout>
  </Show>
);

export default UserShow;
