import React from 'react';
import { ClearButtons, FormatButtons, LinkButtons, ListButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import { BooleanInput, DateTimeInput, ImageField, ImageInput, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { CompleteCalendar } from '@react-admin/ra-calendar';
import spacetime from 'spacetime';

const getColourForCategory = (site_id, segment) => {
  if (site_id === null && segment === null) {
    return 'orange';
  }
  if (site_id !== null && segment === null) {
    return 'blue';
  }
  if (site_id === null && segment !== null) {
    return 'green';
  }
};

const PromotionaloffercalendarList = () => {
  const handleConvertToEvent = event => {
    if (event.daysOfWeek !== null) {
      return {
        id: String(event.id),
        title: event.title,
        allDay: event.allDay,
        daysOfWeek: event.daysOfWeek,
        startRecur: spacetime(event.published).unixFmt('yyyy-MM-ddTHH:mm:ss'),
        endRecur: spacetime(event.expiry).unixFmt('yyyy-MM-ddTHH:mm:ss'),
        startTime: spacetime(event.published).unixFmt('HH:mm:ss'),
        endTime: spacetime(event.expiry).unixFmt('HH:mm:ss'),
        backgroundColor: getColourForCategory(event.site_id, event.segment),
        borderColor: getColourForCategory(event.site_id, event.segment),
        site_id: event.site_id,
        segment: event.segment,
        imageUrl: event.imageUrl,
        headline: event.headline,
        description: event.description,
        isFeatured: event.isFeatured,
        ordering: event.ordering,
        groupId: event.groupId,
        isTriggered: event.isTriggered,
      };
    }
    else {
      return {
        id: String(event.id),
        title: event.title,
        start: spacetime(event.published).unixFmt('yyyy-MM-ddTHH:mm:ss'),
        end: spacetime(event.expiry).unixFmt('yyyy-MM-ddTHH:mm:ss'),
        allDay: event.allDay,
        backgroundColor: getColourForCategory(event.site_id, event.segment),
        borderColor: getColourForCategory(event.site_id, event.segment),
        site_id: event.site_id,
        segment: event.segment,
        imageUrl: event.imageUrl,
        headline: event.headline,
        description: event.description,
        isFeatured: event.isFeatured,
        ordering: event.ordering,
        isTriggered: event.isTriggered,
      };
    }
  };

  const handleTransform = event => {
    return {
      id: parseInt(event.id),
      title: event.title,
      published: spacetime(event.start).unixFmt('yyyy-MM-ddTHH:mm:ss'),
      expiry: spacetime(event.end).unixFmt('yyyy-MM-ddTHH:mm:ss'),
      groupId: event.groupId,
      site_id: event.extendedProps.site_id,
      segment: event.extendedProps.segment,
      imageUrl: event.extendedProps.imageUrl,
      headline: event.extendedProps.headline,
      description: event.extendedProps.description,
      isFeatured: event.extendedProps.isFeatured,
      ordering: event.extendedProps.ordering,
      isTriggered: event.extendedProps.isTriggered,
    };
  };

  const MyRichTextInput = ({ size, ...props }) => (
    <RichTextInput
      toolbar={
        <RichTextInputToolbar>
          <FormatButtons size={size} />
          <ListButtons size={size} />
          <LinkButtons size={size} />
          <ClearButtons size={size} />
        </RichTextInputToolbar>
      }
      {...props}
    />
  );

  const daysOfWeekParser = value => { 
    return value ? JSON.stringify(value) : null;
  };

  const daysOfWeekFormatter = value => {
    return value ? JSON.parse(value) : null;
  };

  const dateTimeParser = value => {
    return spacetime(value).unixFmt('yyyy-MM-ddTHH:mm:ss');
  };

  const dateTimeFormatter = value => {
    return spacetime(value).unixFmt('yyyy-MM-ddTHH:mm:ss');
  };

  return (
    <CompleteCalendar
      CalendarProps={{
        convertToEvent: handleConvertToEvent,
        transform: handleTransform,
        editable: false
      }}>
      <SimpleForm>
        <TextInput source="title" />
        <TextInput source="headline" />
        <DateTimeInput source="published" parse={dateTimeParser} format={dateTimeFormatter} />
        <DateTimeInput source="expiry" parse={dateTimeParser} format={dateTimeFormatter} />
        <BooleanInput source="allDay" />
        <ImageField source="imageUrl" />
        <ImageInput source="imageUrl" label="Image" accept="image/png,image/jpg,image/jpeg">
          <ImageField source="src" />
        </ImageInput>
        <TextInput source="ordering" />
        <TextInput source="groupId" />
        <SelectArrayInput source="daysOfWeek" parse={daysOfWeekParser} format={daysOfWeekFormatter} choices={[
          { id: '0', name: 'Sunday' },
          { id: '1', name: 'Monday' },
          { id: '2', name: 'Tuesday' },
          { id: '3', name: 'Wednesday' },
          { id: '4', name: 'Thursday' },
          { id: '5', name: 'Friday' },
          { id: '6', name: 'Saturday' },
        ]} />
        <MyRichTextInput source="description" label="Description" />
        <BooleanInput source="isFeatured" />
        <ReferenceInput label="Site" source="site_id" reference="sites" perPage={500}>
          <SelectInput optionText="siteName" />
        </ReferenceInput>
        <TextInput source="segment" />
      </SimpleForm>
    </CompleteCalendar>
  );
};

export default PromotionaloffercalendarList;
