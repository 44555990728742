import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { DateField, Datagrid, EditButton, List, ShowButton, TextField, TextInput } from 'react-admin';

const useCampaigncodeListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const CampaigncodeListActionToolbar = ({ children, ...props }) => {
  const classes = useCampaigncodeListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const CampaigncodeFilters = [
  <TextInput source="code" label="Search code" />,
];

export const CampaigncodeList = () => (
  <List filters={CampaigncodeFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="amount" />
      <DateField showTime source="startDateTime" options={{ timeZone: 'UTC'}} />
      <DateField showTime source="endDateTime" options={{ timeZone: 'UTC'}} />
      <TextField source="maxRedemptions" />
      <CampaigncodeListActionToolbar>
          <EditButton />
          <ShowButton />
      </CampaigncodeListActionToolbar>
    </Datagrid>
  </List>
);

export default CampaigncodeList;
