import CampaigncoderedemptionIcon from '@mui/icons-material/Campaign';
import CampaigncoderedemptionCreate from './CampaigncoderedemptionCreate';
import CampaigncoderedemptionEdit from './CampaigncoderedemptionEdit';
import CampaigncoderedemptionList from './CampaigncoderedemptionList';
import CampaigncoderedemptionShow from './CampaigncoderedemptionShow';

const Campaigncoderedemptions = {
    list: CampaigncoderedemptionList,
    create: CampaigncoderedemptionCreate,
    edit: CampaigncoderedemptionEdit,
    show: CampaigncoderedemptionShow,
    icon: CampaigncoderedemptionIcon,
};

export default Campaigncoderedemptions;
