import PaymentcardvalidationIcon from '@mui/icons-material/CreditCard';
// import PaymentcardvalidationCreate from './PaymentcardvalidationCreate';
// import PaymentcardvalidationEdit from './PaymentcardvalidationEdit';
import PaymentcardvalidationList from './PaymentcardvalidationList';
import PaymentcardvalidationShow from './PaymentcardvalidationShow';

const Paymentcardvalidations = {
    list: PaymentcardvalidationList,
    // create: PaymentcardvalidationCreate,
    // edit: PaymentcardvalidationEdit,
    show: PaymentcardvalidationShow,
    icon: PaymentcardvalidationIcon,
};

export default Paymentcardvalidations;
