import * as React from 'react';
import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import JsonFormatter from 'react-json-formatter';

const jsonStyle = {
  propertyStyle: { color: 'red' },
  stringStyle: { color: 'green' },
  numberStyle: { color: 'darkorange' }
};

const DebugInvencoPage = () => {
  const [title, setTitle] = useState('All sites');
  const [sites, setSites] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getSites();
  }, []);

  const getSites = async () => {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL3}/invenco/getSites?sort=desc`,
      headers: {
        'X-Api-Key': process.env.REACT_APP_API_KEY,
        'Authorization': window.localStorage.getItem('_gasAccessToken')
      }
    });
    setTitle('All sites');
    setSites(await res.data);
    setData(await res.data);
  };

  const getSite = async (site) => {
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_API_URL3}/invenco/getSites?site=${site}`,
      headers: {
        'X-Api-Key': process.env.REACT_APP_API_KEY,
        'Authorization': window.localStorage.getItem('_gasAccessToken')
      }
    });
    const rawData = await res.data;
    setTitle(rawData.name);

    let newData = [];
    newData.push(rawData);
    setData(newData);
  };

  const handleChange = (event) => {
    if (event.target.value === 'all') {
      getSites();
    }
    else {
      getSite(event.target.value);
    }
  };

  return (
    <Card>
      <CardHeader title="Debug Invenco" />
      <CardContent>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Sites</InputLabel>
          <Select label="Sites" defaultValue="all" onChange={handleChange}>
            <MenuItem index={0} value="all">All</MenuItem>
            {sites.length > 0 && sites.map((site, index) => (
              <MenuItem key={index} value={site.id}>{site.name}</MenuItem>
            )
            )}
          </Select>
        </FormControl>
        <h3>{title}</h3>
        <code>
          {data.length > 0 &&
            <JsonFormatter json={JSON.stringify(data)} style={jsonStyle} tabWidth={2} />
          }
        </code>
      </CardContent>
    </Card>
  );
};

export default DebugInvencoPage;
