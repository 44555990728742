import TranxactorIcon from '@mui/icons-material/CardGiftcard';
// import TranxactorCreate from './TranxactorCreate';
// import TranxactorEdit from './TranxactorEdit';
import TranxactorList from './TranxactorList';
import TranxactorShow from './TranxactorShow';

const Tranxactors = {
  list: TranxactorList,
  // create: TranxactorCreate,
  // edit: TranxactorEdit,
  show: TranxactorShow,
  icon: TranxactorIcon,
};

export default Tranxactors;
