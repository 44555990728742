import * as React from 'react';
import { BooleanInput, Edit, SimpleForm, useRecordContext } from 'react-admin';

const MaintenancemodeTitle = () => {
  const record = useRecordContext();
  return <span>Maintenance mode {record ? `"${record.id}"` : ''}</span>;
};

const MaintenancemodeEdit = props => (
  <Edit {...props} title={<MaintenancemodeTitle />}>
    <SimpleForm>
      <BooleanInput source="isOn" />
    </SimpleForm>
  </Edit>
);

export default MaintenancemodeEdit;
