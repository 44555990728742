import * as React from 'react';
import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';
import axios from 'axios';
import JsonFormatter from 'react-json-formatter';

const jsonStyle = {
  propertyStyle: { color: 'red' },
  stringStyle: { color: 'green' },
  numberStyle: { color: 'darkorange' }
};

const DebugTranxactorPage = () => {
  const [token, setToken] = useState('');
  const [data, setData] = useState();

  useEffect(() => {
    const getToken = async () => {
      const res = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL3}/tranxactor/getAuthToken`,
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_KEY,
          'Authorization': window.localStorage.getItem('_gasAccessToken')
        }
      });
      const data = await res.data;
      setToken(data.token);
    };
    getToken();
  }, []);

  useEffect(() => {
    const getBalance = async (token) => {
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL3}/tranxactor/postBalance`,
        headers: {
          'X-Api-Key': process.env.REACT_APP_API_KEY,
          'Authorization': window.localStorage.getItem('_gasAccessToken')
        },
        data: JSON.stringify({
          "cardNumber": "6276049136747780",
          "pinNumber": "",
          "authCode": token
        })
      });
      const data = await res.data;
      setData(JSON.stringify(data));
    };
    if (token !== '') {
      getBalance(token);
    }
  }, [token]);

  return (
    <Card>
      <CardHeader title="Debug Tranxactor" />
      <CardContent>
        <h3>Card 6276049136747780</h3>
        <code>
          {data &&
            <JsonFormatter json={data} style={jsonStyle} tabWidth={2} />
          }
        </code>
      </CardContent>
    </Card>
  );
};

export default DebugTranxactorPage;
