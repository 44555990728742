import { ChipField, DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const FormsubmissionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Form submission {record ? `"${record.id}"` : ''}</span>
  );
};

export const FormsubmissionShow = props => (
  <Show {...props} title={<FormsubmissionTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="formSubmission" />
      <DateField showTime source="formSubmissionSent" options={{ timeZone: 'UTC'}} />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default FormsubmissionShow;
