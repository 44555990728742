import * as React from 'react';
import { Create, DateInput, SimpleForm, TextInput, required } from 'react-admin';

const validateAmount = values => {
  const errors = {};
  if (values.amount < 0) {
    errors.amount = 'Amount must be 0 or larger';
  }
  if (values.code.length < 6) {
    errors.code = 'Code must be 6 characters or larger';
  }
  return errors;
};

const CampaigncodeCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateAmount}>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="code" validate={[required()]} />
      <TextInput source="amount" validate={[required()]} />
      <DateInput source="startDateTime" label="Start Date Time" validate={[required()]} />
      <DateInput source="endDateTime" label="End Date Time" validate={[required()]} />
      <TextInput source="maxRedemptions" validate={[required()]} />
    </SimpleForm>
  </Create>
);

export default CampaigncodeCreate;
