import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { BooleanField, Datagrid, DateField, DateTimeInput, List, ShowButton, TextField, TextInput } from 'react-admin';

const useTranxactorListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const TranxactorListActionToolbar = ({ children, ...props }) => {
  const classes = useTranxactorListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const TranxactorFilters = [
  <TextInput source="cardNumber" label="Search Card number" />,
  <DateTimeInput source="expiryDate" label="Search Expiry date" />,
  <TextInput source="expiryReason" label="Search Expiry reason" />
];

export const TranxactorList = () => (
  <List filters={TranxactorFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="cardNumber" />
      <BooleanField looseValue source="isIssued" />
      <DateField source="expiryDate" options={{ timeZone: 'UTC'}} />
      <TextField source="expiryReason" />
      <BooleanField looseValue source="isActive" />
      <TranxactorListActionToolbar>
          {/* <EditButton /> */}
          <ShowButton />
      </TranxactorListActionToolbar>
    </Datagrid>
  </List>
);

export default TranxactorList;
