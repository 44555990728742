import * as React from 'react';
import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput, useRecordContext } from 'react-admin';
import { ClearButtons, FormatButtons, LinkButtons, ListButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';

const MyRichTextInput = ({ size, ...props }) => (
	<RichTextInput
		toolbar={
			<RichTextInputToolbar>
				<FormatButtons size={size} />
				<ListButtons size={size} />
				<LinkButtons size={size} />
				<ClearButtons size={size} />
			</RichTextInputToolbar>
		}
		{...props}
	/>
);

const FrequentlyaskedquestionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Frequently asked question {record ? `"${record.id}"` : ''}</span>
  );
};

const FrequentlyaskedquestionEdit = props => (
  <Edit {...props} title={<FrequentlyaskedquestionTitle />}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="title" />
      <MyRichTextInput source="body" label="Body" />
      <BooleanInput source="isHeading" />
      <NumberInput source="parentId" />
      <NumberInput source="ordering" />
    </SimpleForm>
  </Edit>
);

export default FrequentlyaskedquestionEdit;
