import ReferralamountIcon from '@mui/icons-material/Groups';
import ReferralamountCreate from './ReferralamountCreate';
import ReferralamountEdit from './ReferralamountEdit';
import ReferralamountList from './ReferralamountList';
import ReferralamountShow from './ReferralamountShow';

const Referralamounts = {
    list: ReferralamountList,
    create: ReferralamountCreate,
    edit: ReferralamountEdit,
    show: ReferralamountShow,
    icon: ReferralamountIcon,
};

export default Referralamounts;
