import * as React from 'react';
import { DateField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const CampaigncodeTitle = () => {
  const record = useRecordContext();
  return (
    <span>Campaign code {record ? `"${record.code}"` : ''}</span>
  );
};

export const CampaigncodeShow = props => (
  <Show {...props} title={<CampaigncodeTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="amount" />
      <DateField showTime source="startDateTime" options={{ timeZone: 'UTC'}} />
      <DateField showTime source="endDateTime" options={{ timeZone: 'UTC'}} />
      <TextField source="maxRedemptions" />
    </SimpleShowLayout>
  </Show>
);

export default CampaigncodeShow;
