import * as React from 'react';
import { DateInput, Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const CampaigncodeTitle = () => {
  const record = useRecordContext();
  return (
    <span>Campaign code {record ? `"${record.code}"` : ''}</span>
  );
};

const CampaigncodeEdit = props => (
  <Edit {...props} title={<CampaigncodeTitle />}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
      <TextInput source="amount" />
      <DateInput source="startDateTime" label="Start Date Time" />
      <DateInput source="endDateTime" label="End Date Time" />
      <TextInput source="maxRedemptions" />
    </SimpleForm>
  </Edit>
);

export default CampaigncodeEdit;
