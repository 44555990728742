import * as React from 'react';
import { Create, DateInput, SimpleForm, TextInput } from 'react-admin';

const ReferralamountCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <DateInput source="startDate" label="Start Date" />
      <DateInput source="endDate" label="End Date" />
      <TextInput source="amount" />
    </SimpleForm>
  </Create>
);

export default ReferralamountCreate;
