import FormsubmissionIcon from '@mui/icons-material/Description';
// import FormSubmissionCreate from './FormsubmissionCreate';
// import FormSubmissionEdit from './FormsubmissionEdit';
import FormsubmissionList from './FormsubmissionList';
import FormsubmissionShow from './FormsubmissionShow';

const Formsubmissions = {
  list: FormsubmissionList,
  // create: FormsubmissionCreate,
  // edit: FormsubmissionEdit,
  show: FormsubmissionShow,
  icon: FormsubmissionIcon,
};

export default Formsubmissions;
