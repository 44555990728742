import CampaigncodeIcon from '@mui/icons-material/Abc';
import CampaigncodeCreate from './CampaigncodeCreate';
import CampaigncodeEdit from './CampaigncodeEdit';
import CampaigncodeList from './CampaigncodeList';
import CampaigncodeShow from './CampaigncodeShow';

const Campaigncodes = {
    list: CampaigncodeList,
    create: CampaigncodeCreate,
    edit: CampaigncodeEdit,
    show: CampaigncodeShow,
    icon: CampaigncodeIcon,
};

export default Campaigncodes;
