import MaintenancemodeIcon from '@mui/icons-material/Build';
import MaintenancemodeShow from './MaintenancemodeShow';
// import MaintenancemodeCreate from './MaintenancemodeCreate';
import MaintenancemodeEdit from './MaintenancemodeEdit';
import MaintenancemodeList from './MaintenancemodeList';

const Maintenancemodes = {
  list: MaintenancemodeList,
//   create: MaintenancemodeCreate,
  edit: MaintenancemodeEdit,
  show: MaintenancemodeShow,
  icon: MaintenancemodeIcon,
};

export default Maintenancemodes;
