import SiteIcon from '@mui/icons-material/LocationOn';
import SiteCreate from './SiteCreate';
import SiteEdit from './SiteEdit';
import SiteList from './SiteList';
import SiteShow from './SiteShow';

const Sites = {
    list: SiteList,
    create: SiteCreate,
    edit: SiteEdit,
    show: SiteShow,
    icon: SiteIcon,
};

export default Sites;
