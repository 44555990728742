import UserIcon from '@mui/icons-material/Group';
// import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserList from './UserList';
import UserShow from './UserShow';

const Users = {
    list: UserList,
    // create: UserCreate,
    edit: UserEdit,
    show: UserShow,
    icon: UserIcon,
};

export default Users;
