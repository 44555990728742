import FrequentlyaskedquestionIcon from '@mui/icons-material/Quiz';
import FrequentlyaskedquestionCreate from './FrequentlyaskedquestionCreate';
import FrequentlyaskedquestionEdit from './FrequentlyaskedquestionEdit';
import FrequentlyaskedquestionList from './FrequentlyaskedquestionList';
import FrequentlyaskedquestionShow from './FrequentlyaskedquestionShow';

const Frequentlyaskedquestions = {
    list: FrequentlyaskedquestionList,
    create: FrequentlyaskedquestionCreate,
    edit: FrequentlyaskedquestionEdit,
    show: FrequentlyaskedquestionShow,
    icon: FrequentlyaskedquestionIcon,
};

export default Frequentlyaskedquestions;
