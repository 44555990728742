import { Box, Card, Divider, Typography } from '@mui/material';
import * as React from 'react';
import { createElement } from 'react';
import { Link } from 'react-router-dom';
import cartouche from '../assets/cartouche.png';

const CardWithIcon = (props) => {
  const { icon, title, subtitle, to, children } = props;

  return (
    <Card
      sx={{
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
          textDecoration: 'none',
          color: 'inherit',
        },
      }}
    >
      <Link to={to}>
        <Box
          sx={{
            overflow: 'inherit',
            padding: '16px',
            background: () =>
              `url(${cartouche}) no-repeat`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: () => '#ff8642',
            },
          }}
        >
          <Box width="3em" className="icon">
            {createElement(icon, { fontSize: 'large' })}
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">{title}</Typography>
            <Typography variant="h5" component="h2">
              {subtitle || ' '}
            </Typography>
          </Box>
        </Box>
      </Link>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;
