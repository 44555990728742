import * as React from 'react';
import { useMutation } from 'react-query';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import { BooleanField, ChipField, Datagrid, DateField, NumberField, ReferenceManyField, ReferenceField, Show, SimpleShowLayout, TextField, useDataProvider, useRecordContext } from 'react-admin';

const TransactionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Transaction {record ? `"${record.transactionNumber}"` : ''}</span>
  );
};

const LinkToTransactionEmail = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation(
    ['sendTransactionEmail'],
    () => dataProvider.sendTransactionEmail(record.transactionUuid)
  );
  const transactionEmailLabel = isLoading ? 'Sending...' : 'Resend Receipt Email';

  return record && record.paymentResult === 'APPROVED' ? (
    <Button disabled={isLoading} onClick={() => mutate()} color="secondary" variant="outlined" startIcon={<EmailIcon />}>
      {transactionEmailLabel}
    </Button>
  ) : null;
};

export const TransactionShow = props => (
  <Show {...props} title={<TransactionTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="transactionNumber" />
      <TextField source="transactionUuid" />
      <DateField showTime source="transactionDateTime" options={{ timeZone: 'UTC'}} />
      <TextField source="paymentResult" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="fuelCostAfterDiscount" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="fuelCostGst" />
      <NumberField options={{ style: 'unit', unit: 'liter', unitDisplay: 'long' }} source="fuelAmount" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="fuelPrice" />
      <ReferenceField label="Site" source="site_id" reference="sites">
        <ChipField source="siteName" />
      </ReferenceField>
      <TextField source="pumpNumber" />
      <TextField source="smartFuelCard" label="AA Smartfuel Card" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="smartFuelDiscountTodaysDeal" label="AA Smartfuel Today's Deal" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="smartFuelDiscountAvailable" label="AA Smartfuel Discount Available" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="smartFuelDiscountRedeemed" label="AA Smartfuel Discount Redeemed" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="smartFuelDiscountAccumulated" label="AA Smartfuel Discount Accumulated" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="smartFuelDiscountBalance" label="AA Smartfuel Discount Balance" />
      <TextField source="triquestraLoyaltyCard" label="GAS Instant Discounts Loyalty Card" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="triquestraLoyaltyDiscountTodaysDeal" label="GAS Instant Discounts Today's Deal" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="triquestraLoyaltyDiscountAvailable" label="GAS Instant Discounts Discount Available" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="triquestraLoyaltyDiscountRedeemed" label="GAS Instant Discounts Discount Redeemed" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="triquestraLoyaltyDiscountBalance" label="GAS Instant Discounts Discount Balance" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="fuelCostBeforeDiscount" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceManyField label="Transaction gift cards" reference="transactiongiftcards" target="transaction_id">
        <Datagrid>
          <ReferenceField label="Gift card" source="giftcard_id" reference="giftcards">
            <ChipField source="tranxactorCard" />
          </ReferenceField>
          <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="amount" />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceField label="Payment card" source="paymentcard_id" reference="paymentcards">
        <ChipField source="cardNumber" />
      </ReferenceField>
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="paidByCard" />
      <ReferenceField label="Fuel grade" source="fuelgrade_id" reference="fuelgrades">
        <ChipField source="fuelGrade" />
      </ReferenceField>
      <TextField source="triquestraInvoiceId" />
      <BooleanField looseValue source="isEmailSent" />
      <LinkToTransactionEmail />
    </SimpleShowLayout>
  </Show>
);

export default TransactionShow;
