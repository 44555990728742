import PromotionalofferIcon from '@mui/icons-material/LocalOffer';
import PromotionalofferCreate from './PromotionalofferCreate';
import PromotionalofferEdit from './PromotionalofferEdit';
import PromotionalofferList from './PromotionalofferList';
import PromotionalofferShow from './PromotionalofferShow';

const Promotionaloffers = {
  list: PromotionalofferList,
  create: PromotionalofferCreate,
  edit: PromotionalofferEdit,
  show: PromotionalofferShow,
  icon: PromotionalofferIcon,
};

export default Promotionaloffers;
