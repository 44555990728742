import PaymentcardIcon from '@mui/icons-material/CreditCard';
// import PaymentcardCreate from './PaymentcardCreate';
import PaymentcardEdit from './PaymentcardEdit';
import PaymentcardList from './PaymentcardList';
import PaymentcardShow from './PaymentcardShow';

const Paymentcards = {
    list: PaymentcardList,
    // create: PaymentcardCreate,
    edit: PaymentcardEdit,
    show: PaymentcardShow,
    icon: PaymentcardIcon,
};

export default Paymentcards;
