import PromotionaloffercalendarIcon from '@mui/icons-material/LocalOffer';
import PromotionaloffercalendarCreate from './PromotionaloffercalendarCreate';
// import PromotionaloffercalendarEdit from './PromotionaloffercalendarEdit';
import PromotionaloffercalendarList from './PromotionaloffercalendarList';
import PromotionaloffercalendarShow from './PromotionaloffercalendarShow';

const Promotionalofferscalendar = {
  list: PromotionaloffercalendarList,
  create: PromotionaloffercalendarCreate,
  // edit: PromotionaloffercalendarEdit,
  show: PromotionaloffercalendarShow,
  icon: PromotionaloffercalendarIcon,
};

export default Promotionalofferscalendar;
