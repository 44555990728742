import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { AutocompleteInput, BooleanField, BooleanInput, ChipField, Datagrid, EditButton, List, ReferenceField, ReferenceInput, ShowButton, TextField, TextInput } from 'react-admin';

const usePaymentcardListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const PaymentcardListActionToolbar = ({ children, ...props }) => {
  const classes = usePaymentcardListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const paymentcardFilters = [
  <TextInput source="cardNumber" label="Search Card number" />,
  <TextInput source="dateExpiryMonth" label="Search Date expiry month" />,
  <TextInput source="dateExpiryYear" label="Search Date expiry year" />,
  <TextInput source="cardType" label="Search Card type" />,
  <TextInput source="friendlyName" label="Search Friendly name" />,
  <ReferenceInput source="user_id" reference="users" label="Search Users">
    <AutocompleteInput optionText="email" label="Search Users" />
  </ReferenceInput>,
  <BooleanInput source="isActive" label="Search Is active" />,
  <BooleanInput source="isDefault" label="Search Is default" />,
];

export const PaymentcardList = () => (
  <List filters={paymentcardFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="cardNumber" />
      <TextField source="dateExpiryMonth" />
      <TextField source="dateExpiryYear" />
      <TextField source="cardType" />
      <TextField source="friendlyName" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <BooleanField looseValue source="isActive" />
      <BooleanField looseValue source="isDefault" />
      <PaymentcardListActionToolbar>
          <EditButton />
          <ShowButton />
      </PaymentcardListActionToolbar>
    </Datagrid>
  </List>
);

export default PaymentcardList;
