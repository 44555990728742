import * as React from 'react';
import { ChipField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const GiftcardTitle = () => {
  const record = useRecordContext();
  return (
    <span>Gift card {record ? `"${record.tranxactorCard}"` : ''}</span>
  );
};

export const GiftcardShow = props => (
  <Show {...props} title={<GiftcardTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="phone" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <TextField source="tranxactorCard" />
    </SimpleShowLayout>
  </Show>
);

export default GiftcardShow;
