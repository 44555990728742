import { makeStyles } from '@material-ui/core/styles';
import { Children, cloneElement } from 'react';
import { AutocompleteInput, ChipField, Datagrid, DateField, DateTimeInput, List, ReferenceField, ReferenceInput, ShowButton, TextField, TextInput } from 'react-admin';

const useNotificationListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const NotificationListActionToolbar = ({ children, ...props }) => {
  const classes = useNotificationListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {
        Children.map(children, button => cloneElement(button, props))
      }
    </div>
  );
};

const notificationFilters = [
  <TextInput source="notificationType" label="Search Notification type" />,
  <DateTimeInput source="notificationSent" label="Search Notification sent" />,
  <DateTimeInput source="notificationRead" label="Search Notification read" />,
  <ReferenceInput source="user_id" reference="users" label="Search Users">
    <AutocompleteInput optionText="email" label="Search Users" />
  </ReferenceInput>,
];

export const NotificationList = () => (
  <List filters={notificationFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <DateField showTime source="notificationSent" options={{ timeZone: 'UTC'}} />
      <TextField source="notificationType" />
      <DateField showTime source="notificationRead" options={{ timeZone: 'UTC'}} />
      <ReferenceField label="Promotional offer" source="promotionaloffer_id" reference="promotionaloffers">
        <ChipField source="title" />
      </ReferenceField>
      <ReferenceField label="Transaction" source="transaction_id" reference="transactions">
        <ChipField source="transactionNumber" />
      </ReferenceField>
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <NotificationListActionToolbar>
        {/* <EditButton /> */}
        <ShowButton />
      </NotificationListActionToolbar>
    </Datagrid>
  </List>
);

export default NotificationList;
