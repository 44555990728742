import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { ChipField, DateInput, DateField, Datagrid, List, ReferenceField, ShowButton, TextField, TextInput } from 'react-admin';

const usePaymentcardvalidationListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const PaymentcardvalidationListActionToolbar = ({ children, ...props }) => {
  const classes = usePaymentcardvalidationListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const paymentcardvalidationFilters = [
  <TextInput source="transactionNumber" label="Search Transaction number" />,
  <TextInput source="responseText" label="Search Response text" />,
  <DateInput source="transactionDateTime" label="Search Transaction date" />,
];

export const PaymentcardvalidationList = () => (
  <List filters={paymentcardvalidationFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="transactionNumber" />
      <DateField showTime source="transactionDateTime" options={{ timeZone: 'UTC' }} />
      <TextField source="responseText" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <PaymentcardvalidationListActionToolbar>
          <ShowButton />
      </PaymentcardvalidationListActionToolbar>
    </Datagrid>
  </List>
);

export default PaymentcardvalidationList;
