import * as React from 'react';
import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const GiftcardTitle = () => {
  const record = useRecordContext();
  return (
    <span>Gift card {record ? `"${record.tranxactorCard}"` : ''}</span>
  );
};

const GiftcardEdit = props => (
  <Edit {...props} title={<GiftcardTitle />}>
    <SimpleForm>
      <TextInput source="phone" />
      <ReferenceInput label="User" source="user_id" reference="users">
          <SelectInput optionText="email" />
      </ReferenceInput>
      <TextInput source="tranxactorCard" />
    </SimpleForm>
  </Edit>
);

export default GiftcardEdit;
