import * as React from 'react';
import { Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const FuelgradeTitle = () => {
  const record = useRecordContext();
  return (
    <span>Fuel grade {record ? `"${record.fuelGrade}"` : ''}</span>
  );
};

const FuelgradeEdit = props => (
  <Edit {...props} title={<FuelgradeTitle />}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="fuelGrade" />
      <TextInput source="colour" />
    </SimpleForm>
  </Edit>
);

export default FuelgradeEdit;
