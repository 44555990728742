import * as React from 'react';
import { Show, SimpleShowLayout, useRecordContext, BooleanField } from 'react-admin';

const MaintenancemodeTitle = () => {
  const record = useRecordContext();
  return <span>Maintenance mode {record ? `"${record.id}"` : ''}</span>;
};

const MaintenancemodeShow = props => (
  <Show {...props} title={<MaintenancemodeTitle />}>
    <SimpleShowLayout>
      <BooleanField source="isOn" looseValue />
    </SimpleShowLayout>
  </Show>
);

export default MaintenancemodeShow;
