import * as React from 'react';
import { BooleanInput, DateTimeInput, Create, ImageField, ImageInput, ReferenceInput, SelectArrayInput, SelectInput, SimpleForm, TextInput, required } from 'react-admin';
import { ClearButtons, FormatButtons, LinkButtons, ListButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';
import spacetime from 'spacetime';

const MyRichTextInput = ({ size, ...props }) => (
	<RichTextInput
		toolbar={
			<RichTextInputToolbar>
				<FormatButtons size={size} />
				<ListButtons size={size} />
				<LinkButtons size={size} />
				<ClearButtons size={size} />
			</RichTextInputToolbar>
		}
		{...props}
	/>
);

const daysOfWeekParser = value => { 
  return value ? JSON.stringify(value) : null;
};

const daysOfWeekFormatter = value => {
  return value ? JSON.parse(value) : null;
};

const dateTimeParser = value => {
  return spacetime(value).unixFmt('yyyy-MM-ddTHH:mm:ss');
};

const dateTimeFormatter = value => {
  return spacetime(value).unixFmt('yyyy-MM-ddTHH:mm:ss');
};

const PromotionalofferCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="headline" />
      <DateTimeInput source="published" parse={dateTimeParser} format={dateTimeFormatter} label="Start Date" />
      <DateTimeInput source="expiry" parse={dateTimeParser} format={dateTimeFormatter} label="End Date" />
      <BooleanInput source="allDay" />
      <SelectArrayInput source="daysOfWeek" parse={daysOfWeekParser} format={daysOfWeekFormatter} choices={[
          { id: '0', name: 'Sunday' },
          { id: '1', name: 'Monday' },
          { id: '2', name: 'Tuesday' },
          { id: '3', name: 'Wednesday' },
          { id: '4', name: 'Thursday' },
          { id: '5', name: 'Friday' },
          { id: '6', name: 'Saturday' },
        ]} />
      <ImageInput source="imageUrl" label="Image" accept="image/png,image/jpg,image/jpeg" validate={[required()]}>
        <ImageField source="src" />
      </ImageInput>
      <TextInput source="ordering" validate={[required()]} />
      <TextInput source="groupId" />
      <MyRichTextInput source="description" label="Description" />
      <BooleanInput source="isFeatured" />
      <ReferenceInput label="Site" source="site_id" reference="sites" perPage={500}>
          <SelectInput optionText="siteName" />
      </ReferenceInput>
      <TextInput source="segment" />
    </SimpleForm>
  </Create>
);

export default PromotionalofferCreate;
