import RatingIcon from '@mui/icons-material/Star';
// import RatingCreate from './RatingCreate';
// import RatingEdit from './RatingEdit';
import RatingList from './RatingList';
import RatingShow from './RatingShow';

const Ratings = {
  list: RatingList,
  // create: RatingCreate,
  // edit: RatingEdit,
  show: RatingShow,
  icon: RatingIcon,
};

export default Ratings;
