import TransactionIcon from '@mui/icons-material/ShoppingCart';
// import TransactionCreate from './TransactionCreate';
// import TransactionEdit from './TransactionEdit';
import TransactionList from './TransactionList';
import TransactionShow from './TransactionShow';

const Transactions = {
  list: TransactionList,
  // create: TransactionCreate,
  // edit: TransactionEdit,
  show: TransactionShow,
  icon: TransactionIcon,
};

export default Transactions;
