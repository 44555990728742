import * as React from 'react';
import { BooleanInput, Edit, ReferenceInput, SelectInput, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const PaymentcardTitle = () => {
  const record = useRecordContext();
  return (
    <span>Payment card {record ? `"${record.cardNumber}"` : ''}</span>
  );
};

const PaymentcardEdit = props => (
  <Edit {...props} title={<PaymentcardTitle />}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="cardNumber" />
      <TextInput source="dateExpiryMonth" />
      <TextInput source="dateExpiryYear" />
      <SelectInput source="cardType" choices={[
          { id: 'Visa', name: 'Visa' },
          { id: 'Mastercard', name: 'Mastercard' },
          { id: 'Amex', name: 'Amex' },
      ]} />
      <TextInput source="token" />
      <TextInput source="friendlyName" />
      <ReferenceInput label="User" source="user_id" reference="users">
          <SelectInput optionText="email" />
      </ReferenceInput>
      <BooleanInput source="isActive" />
      <BooleanInput source="isDefault" />
    </SimpleForm>
  </Edit>
);

export default PaymentcardEdit;
