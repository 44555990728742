import * as React from 'react';
import { BooleanField, DateField, ChipField, FunctionField, ImageField, RichTextField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const PromotionalofferTitle = () => {
  const record = useRecordContext();
  return (
    <span>Promotional offer {record ? `"${record.title}"` : ''}</span>
  );
};

const DaysOfWeekRender = (record) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  if (record.daysOfWeek) {
    return (
      <ul>
        {
          JSON.parse(record.daysOfWeek)
            .map((day) => <li key={day}>{days[day]}</li>)
        }
      </ul>
    );
  }
  else {
    return <span>-</span>;
  }
};

export const PromotionalofferShow = props => (
  <Show {...props} title={<PromotionalofferTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="title" />
      <TextField source="headline" />
      <DateField source="published" options={{ timeZone: 'UTC'}} />
      <DateField source="expiry" options={{ timeZone: 'UTC'}} />
      <BooleanField looseValue source="allDay" />
      <ImageField source="imageUrl" />
      <TextField source="ordering" />
      <FunctionField label="Days of Week" render={DaysOfWeekRender} />
      <RichTextField source="description" />
      <BooleanField looseValue source="isFeatured" />
      <ReferenceField label="Site" source="site_id" reference="sites">
        <ChipField source="siteName" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default PromotionalofferShow;
