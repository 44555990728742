import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { BooleanField, ChipField, Datagrid, DateField, List, ReferenceField, ShowButton, TextField, TextInput } from 'react-admin';

const useGiftcardtransactionListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const GiftcardtransactionListActionToolbar = ({ children, ...props }) => {
  const classes = useGiftcardtransactionListActionToolbarStyles();
  return <div className={classes.toolbar}>{Children.map(children, button => cloneElement(button, props))}</div>;
};

const giftcardtransactionFilters = [<TextInput source="transactionNumber" label="Search Transaction number" />];

export const GiftcardtransactionList = () => (
  <List filters={giftcardtransactionFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="transactionNumber" />
      <DateField showTime source="transactionDateTime" options={{ timeZone: 'UTC' }} />
      <TextField source="paymentResult" />
      <TextField source="phone" />
      <TextField source="amount" />
      <BooleanField looseValue source="isGift" />
      <BooleanField looseValue source="isCampaign" />
      <ReferenceField label="Gift card" source="giftcard_id" reference="giftcards">
        <ChipField source="tranxactorCard" />
      </ReferenceField>
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceField label="Payment card" source="paymentcard_id" reference="paymentcards">
        <ChipField source="cardNumber" />
      </ReferenceField>
      <GiftcardtransactionListActionToolbar>
        {/* <EditButton /> */}
        <ShowButton />
      </GiftcardtransactionListActionToolbar>
    </Datagrid>
  </List>
);

export default GiftcardtransactionList;
