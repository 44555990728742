import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { AutocompleteInput, ChipField, Datagrid, EditButton, List, ReferenceField, ReferenceInput, ShowButton, TextField, TextInput } from 'react-admin';

const useGiftcardListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const GiftcardListActionToolbar = ({ children, ...props }) => {
  const classes = useGiftcardListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const giftcardFilters = [
  <TextInput source="tranxactorCard" label="Search Tranxactor card" />,
  <ReferenceInput source="user_id" reference="users" label="Search Users">
    <AutocompleteInput optionText="email" label="Search Users" />
  </ReferenceInput>,
];

export const GiftcardList = () => (
  <List filters={giftcardFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="phone" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <TextField source="tranxactorCard" />
      <GiftcardListActionToolbar>
          <EditButton />
          <ShowButton />
      </GiftcardListActionToolbar>
    </Datagrid>
  </List>
);

export default GiftcardList;
