import { makeStyles } from '@material-ui/core/styles';
import { Children, cloneElement } from 'react';
import { AutocompleteInput, ChipField, Datagrid, DateField, DateTimeInput, List, ReferenceField, ReferenceInput, ShowButton, TextField, TextInput } from 'react-admin';

const useRatingListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const RatingListActionToolbar = ({ children, ...props }) => {
  const classes = useRatingListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {
        Children.map(children, button => cloneElement(button, props))
      }
    </div>
  );
};

const RatingFilters = [
  <TextInput source="rating" label="Search Rating" />,
  <TextInput source="review" label="Search Review" />,
  <DateTimeInput source="ratingSent" label="Search Rating sent" />,
  <ReferenceInput source="user_id" reference="users" label="Search Users">
    <AutocompleteInput optionText="email" label="Search Users" />
  </ReferenceInput>,
];

export const RatingList = () => (
  <List filters={RatingFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="rating" />
      <TextField source="review" />
      <DateField showTime source="ratingSent" options={{ timeZone: 'UTC'}} />
      <ReferenceField label="Site" source="site_id" reference="sites">
        <ChipField source="siteName" />
      </ReferenceField>
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <RatingListActionToolbar>
        {/* <EditButton /> */}
        <ShowButton />
      </RatingListActionToolbar>
    </Datagrid>
  </List>
);

export default RatingList;
