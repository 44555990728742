import * as React from 'react';
import { AutocompleteInput, DateInput, Edit, SimpleForm, ReferenceInput, useRecordContext } from 'react-admin';

const CampaigncoderedemptionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Campaign code redemption {record ? `"${record.redeemedDateTime}"` : ''}</span>
  );
};

const CampaigncoderedemptionEdit = props => (
  <Edit {...props} title={<CampaigncoderedemptionTitle />}>
    <SimpleForm>
      <ReferenceInput source="user_id" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput source="campaigncode_id" reference="campaigncodes">
        <AutocompleteInput optionText="code" />
      </ReferenceInput>
      <ReferenceInput source="giftcard_id" reference="giftcards">
        <AutocompleteInput optionText="tranxactorCard" />
      </ReferenceInput>
      <DateInput source="redeemedDateTime" label="Redeemed Date Time" />
    </SimpleForm>
  </Edit>
);

export default CampaigncoderedemptionEdit;
