import ReferralsIcon from '@mui/icons-material/Build';
import ReferralsShow from './ReferralsShow';
// import ReferralsCreate from './ReferralsCreate';
import ReferralsEdit from './ReferralsEdit';
import ReferralsList from './ReferralsList';

const Referrals = {
  list: ReferralsList,
//   create: ReferralsCreate,
  edit: ReferralsEdit,
  show: ReferralsShow,
  icon: ReferralsIcon,
};

export default Referrals;
