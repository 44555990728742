import * as React from 'react';
import { Show, SimpleShowLayout, useRecordContext, BooleanField } from 'react-admin';

const ReferralsTitle = () => {
  const record = useRecordContext();
  return <span>Referrals {record ? `"${record.id}"` : ''}</span>;
};

const ReferralsShow = props => (
  <Show {...props} title={<ReferralsTitle />}>
    <SimpleShowLayout>
      <BooleanField source="isOn" looseValue />
    </SimpleShowLayout>
  </Show>
);

export default ReferralsShow;
