import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { Datagrid, EditButton, List, ShowButton, TextField, TextInput } from 'react-admin';

const useServiceListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const ServiceListActionToolbar = ({ children, ...props }) => {
  const classes = useServiceListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const serviceFilters = [
  <TextInput source="service" label="Search Service" />,
];

const ServiceList = () => (
  <List filters={serviceFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="service" />
      <ServiceListActionToolbar>
          <EditButton />
          <ShowButton />
      </ServiceListActionToolbar>
    </Datagrid>
  </List>
);

export default ServiceList;
