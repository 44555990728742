import NotificationIcon from '@mui/icons-material/Notifications';
// import NotificationCreate from './NotificationCreate';
// import NotificationEdit from './NotificationEdit';
import NotificationList from './NotificationList';
import NotificationShow from './NotificationShow';

const Notifications = {
  list: NotificationList,
  // create: NotificationCreate,
  // edit: NotificationEdit,
  show: NotificationShow,
  icon: NotificationIcon,
};

export default Notifications;
