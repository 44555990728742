import * as React from 'react';
import { BooleanInput, Create, NumberInput, SimpleForm, TextInput } from 'react-admin';
import { ClearButtons, FormatButtons, LinkButtons, ListButtons, RichTextInput, RichTextInputToolbar } from 'ra-input-rich-text';

const MyRichTextInput = ({ size, ...props }) => (
	<RichTextInput
		toolbar={
			<RichTextInputToolbar>
				<FormatButtons size={size} />
				<ListButtons size={size} />
				<LinkButtons size={size} />
				<ClearButtons size={size} />
			</RichTextInputToolbar>
		}
		{...props}
	/>
);

const FrequentlyaskedquestionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <MyRichTextInput source="body" label="Body" />
      <BooleanInput source="isHeading" />
      <NumberInput source="parentId" />
      <NumberInput source="ordering" />
    </SimpleForm>
  </Create>
);

export default FrequentlyaskedquestionCreate;
