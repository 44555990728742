import { Card, CardContent, CardHeader } from '@mui/material';
// import { startOfDay, subDays } from 'date-fns';
import * as React from 'react';
// import { useMemo } from 'react';
// import { useGetList } from 'react-admin';
import logo from './assets/gas-logo.png';
// import NewTransactions from './dashboard/NewTransactions';
// import NewRevenue from './dashboard/NewRevenue';
// import NewUsers from './dashboard/NewUsers';
// import DebugSmartfuel from './dashboard/DebugSmartfuel';
import DebugTranxactor from './dashboard/DebugTranxactor';
import DebugInvenco from './dashboard/DebugInvenco';

export const Dashboard = () => {
  // const aWeekAgo = useMemo(() => subDays(startOfDay(new Date()), 7), []);

  // // users
  // const users = useGetList('users', {
  //   sort: { field: 'firstSeen', order: 'DESC' },
  //   pagination: { page: 1, perPage: 9999999999 },
  //   // filter: { firstSeen: aWeekAgo.toISOString() },
  //   filter: { isActive: 1 },
  // });

  // // console.log('users', users.data.length);

  // const newUsers = users.data ? users.data.filter(nu => nu.firstSeen > aWeekAgo.toISOString()).reduce(nu => ++nu, 0) : 0;

  // // transactions
  // const transactions = useGetList('transactions', {
  //   sort: { field: 'transactionDateTime', order: 'DESC' },
  //   pagination: { page: 1, perPage: 9999999999 },
  // });

  // const aggregation = useMemo(() => {
  //   if (!transactions.data) return {};
  //   const aggregations = transactions.data
  //     .filter(transaction => transaction.paymentResult === 'APPROVED')
  //     .reduce(
  //       (stats, transaction) => {
  //         if (transaction.transactionDateTime > aWeekAgo.toISOString()) {
  //           stats.newTransactions++;
  //           stats.newRevenue += transaction.fuelCostAfterDiscount;
  //         }
  //         return stats;
  //       },
  //       {
  //         newTransactions: 0,
  //         newRevenue: 0,
  //       }
  //     );
  //   return {
  //     newTransactions: aggregations.newTransactions,
  //     newRevenue: aggregations.newRevenue.toLocaleString(undefined, {
  //       style: 'currency',
  //       currency: 'NZD',
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2,
  //     }),
  //   };
  // }, [transactions, aWeekAgo]);

  // const { newTransactions, newRevenue } = aggregation;

  return (
    <Card>
      <CardHeader title="Welcome to the GAS mobile app administration" />
      <CardContent>
        <div style={{ textAlign: 'center', margin: 20 }}>
          <img src={logo} alt="logo" />
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ padding: 5 }}>
            <NewTransactions value={newTransactions} />
          </div>
          <div style={{ padding: 5 }}>
            <NewRevenue value={newRevenue} />
          </div>
          <div style={{ padding: 5 }}>
            <NewUsers value={newUsers} />
          </div>
        </div> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <div style={{ padding: 5 }}>
            <DebugSmartfuel />
          </div> */}
          <div style={{ padding: 5 }}>
            <DebugTranxactor />
          </div>
          <div style={{ padding: 5 }}>
            <DebugInvenco />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
