import FuelgradeIcon from '@mui/icons-material/LocalGasStation';
import FuelgradeCreate from './FuelgradeCreate';
import FuelgradeEdit from './FuelgradeEdit';
import FuelgradeList from './FuelgradeList';
import FuelgradeShow from './FuelgradeShow';

const Fuelgrades = {
    list: FuelgradeList,
    create: FuelgradeCreate,
    edit: FuelgradeEdit,
    show: FuelgradeShow,
    icon: FuelgradeIcon,
};

export default Fuelgrades;
