import { defaultTheme } from 'react-admin';

export const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaLayout: {
      styleOverrides: {
        root: {
          backgroundColor: '#FDFBF1',
          '& .RaLayout-content': {
            backgroundColor: '#FDFBF1'
          }
        }
      }
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#FF8642'
        }
      }
    }
  }
};
