import * as React from 'react';
import { BooleanField, DateField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const TranxactorTitle = () => {
  const record = useRecordContext();
  return (
    <span>Card number {record ? `"${record.cardNumber}"` : ''}</span>
  );
};

export const TranxactorShow = props => (
  <Show {...props} title={<TranxactorTitle />}>
    <SimpleShowLayout>
    <TextField label="ID" source="id" />
      <TextField source="cardNumber" />
      <BooleanField looseValue source="isIssued" />
      <DateField source="expiryDate" options={{ timeZone: 'UTC'}} />
      <TextField source="expiryReason" />
      <BooleanField looseValue source="isActive" />
    </SimpleShowLayout>
  </Show>
);

export default TranxactorShow;
