import * as React from 'react';
import { DateField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const ReferralamountTitle = () => {
  const record = useRecordContext();
  return (
    <span>Referral amount {record ? `"${record.amount}"` : ''}</span>
  );
};

export const ReferralamountShow = props => (
  <Show {...props} title={<ReferralamountTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <DateField showTime source="startDate" options={{ timeZone: 'UTC'}} />
      <DateField showTime source="endDate" options={{ timeZone: 'UTC'}} />
      <TextField source="amount" />
    </SimpleShowLayout>
  </Show>
);

export default ReferralamountShow;
