// in src/MyLoginPage.js
import * as React from 'react';
import { useState } from 'react';
import { Card, CardContent, CardHeader, Container, Paper, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { useLogin, useNotify } from 'react-admin';
import logo from './assets/gas-logo.png';

const MyLoginPage = ({ theme }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = e => {
    e.preventDefault();
    login({ username: email, password })
      .then((e) => {
        window.localStorage.setItem('_gasAccessToken', e.signInUserSession.accessToken.jwtToken);
        // console.log(e.signInUserSession.accessToken.jwtToken);
      })
      .catch(() =>
        notify('Invalid email or password')
      );
  };

  return (
    <div style={{ backgroundColor: '#FF8642', minHeight: '100vh' }}>
      <Container style={{ paddingTop: '6rem' }}>
        <Paper style={{ width: 350, margin: '0 auto', textAlign: 'center' }}>
          <Card>
            <CardHeader title="GAS mobile app administration" />
            <CardContent>
              <div style={{ marginBottom: 20 }}>
                <img src={logo} alt="logo" />
              </div>
              <form onSubmit={handleSubmit} autoComplete="off" style={{ display: 'flex', flexDirection: 'column' }}>
                <TextField style={{ margin: 10 }} variant="filled" label="Email" required type="email" onChange={e => setEmail(e.target.value)} />
                <TextField style={{ margin: 10 }} variant="filled" label="Password" required type="password" onChange={e => setPassword(e.target.value)} />
                <Button
                  style={{ margin: 10, backgroundColor: '#FF8642' }}
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Sign in
                </Button>
              </form>
            </CardContent>
          </Card>
        </Paper>
      </Container>
    </div>
  );
};

export default MyLoginPage;
