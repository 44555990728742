import * as React from 'react';
import { BooleanField, ChipField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const PaymentcardTitle = () => {
  const record = useRecordContext();
  return <span>Payment card {record ? `"${record.cardNumber}"` : ''}</span>;
};

export const PaymentcardShow = props => (
  <Show {...props} title={<PaymentcardTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="cardNumber" />
      <TextField source="dateExpiryMonth" />
      <TextField source="dateExpiryYear" />
      <TextField source="cardType" />
      <TextField source="token" />
      <TextField source="friendlyName" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <BooleanField looseValue source="isActive" />
      <BooleanField looseValue source="isDefault" />
    </SimpleShowLayout>
  </Show>
);

export default PaymentcardShow;
