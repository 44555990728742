import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { AutocompleteInput, BooleanField, ChipField, Datagrid, DateField, DateInput, ExportButton, FilterButton, List, NumberField, ReferenceField, ReferenceInput, ShowButton, TextField, TextInput, TopToolbar } from 'react-admin';

const useTransactionListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const TransactionListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton maxResults={50000} />
  </TopToolbar>
);


const TransactionListActionToolbar = ({ children, ...props }) => {
  const classes = useTransactionListActionToolbarStyles();
  return (
    <div className={classes.toolbar}>
      {
        Children.map(children, button => cloneElement(button, props))
      }
    </div>
  );
};

const transactionFilters = [
  <TextInput source="transactionNumber" label="Search Transaction number" />,
  <DateInput source="transactionDateTime" label="Search Transaction date" />,
  <ReferenceInput source="site_id" reference="sites" label="Search Sites">
    <AutocompleteInput optionText="siteName" label="Search Sites" suggestionLimit={250} />
  </ReferenceInput>,
  <ReferenceInput source="user_id" reference="users" label="Search Users">
    <AutocompleteInput optionText="email" label="Search Users" suggestionLimit={250} />
  </ReferenceInput>,
];

export const TransactionList = () => (
  <List actions={<TransactionListActions />} filters={transactionFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="transactionNumber" />
      <DateField showTime source="transactionDateTime" options={{ timeZone: 'UTC' }} />
      <TextField source="paymentResult" />
      <NumberField options={{ style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' }} source="fuelCostAfterDiscount" />
      <ReferenceField label="Site" source="site_id" reference="sites">
        <ChipField source="siteName" />
      </ReferenceField>
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <BooleanField looseValue source="isEmailSent" label="Email receipts enabled" />
      <TransactionListActionToolbar>
        {/* <EditButton /> */}
        <ShowButton />
      </TransactionListActionToolbar>
    </Datagrid>
  </List>
);

export default TransactionList;
