import * as React from 'react';
import { Layout } from 'react-admin';
import { MultiLevelMenu, AppLocationContext } from '@react-admin/ra-navigation';
import DashboardIcon from '@mui/icons-material/Dashboard';
import UserIcon from '@mui/icons-material/Group';
import PaymentcardIcon from '@mui/icons-material/CreditCard';
import PaymentcardvalidationIcon from '@mui/icons-material/CreditCard';
import TransactionIcon from '@mui/icons-material/ShoppingCart';
import SiteIcon from '@mui/icons-material/LocationOn';
import PromotionalofferIcon from '@mui/icons-material/LocalOffer';
import CampaigncodeIcon from '@mui/icons-material/Abc';
import CampaigncoderedemptionIcon from '@mui/icons-material/Campaign';
// import NewuserofferIcon from '@mui/icons-material/LabelImportant';
// import AasfofferIcon from '@mui/icons-material/TipsAndUpdates';
import NotificationIcon from '@mui/icons-material/Notifications';
import FormsubmissionIcon from '@mui/icons-material/Description';
import RatingIcon from '@mui/icons-material/Star';
import AddCardIcon from '@mui/icons-material/AddCard';
import GiftcardIcon from '@mui/icons-material/CardGiftcard';
import GiftcardtransactionIcon from '@mui/icons-material/Receipt';
import TranxactorIcon from '@mui/icons-material/CardGiftcard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import FuelgradeIcon from '@mui/icons-material/LocalGasStation';
import ServiceIcon from '@mui/icons-material/RoomService';
import ReferralamountIcon from '@mui/icons-material/Groups';
import MaintenanceIcon from '@mui/icons-material/Build';
import ReferralsIcon from '@mui/icons-material/Build';
import FrequentlyaskedquestionIcon from '@mui/icons-material/Quiz';

const LeftHandSideMenu = () => (
  <MultiLevelMenu>
    <MultiLevelMenu.Item name="dashboard" to="/" exact="true" label="Dashboard" icon={<DashboardIcon />} />
    <MultiLevelMenu.Item name="" to="" label="Users" icon={<UserIcon />}>
      <MultiLevelMenu.Item name="users" to="./users" label="Profiles" icon={<UserIcon />} />
      <MultiLevelMenu.Item name="paymentcards" to="./paymentcards" label="Payment Cards" icon={<PaymentcardIcon />} />
      <MultiLevelMenu.Item name="paymentcardvalidations" to="./paymentcardvalidations" label="Payment Card Validations" icon={<PaymentcardvalidationIcon />} />
      <MultiLevelMenu.Item name="transactions" to="./transactions" label="Transactions" icon={<TransactionIcon />} />
    </MultiLevelMenu.Item>
    <MultiLevelMenu.Item name="" to="" label="Locations" icon={<SiteIcon />}>
      <MultiLevelMenu.Item name="sites" to="./sites" label="Sites" icon={<SiteIcon />} />
    </MultiLevelMenu.Item>
    <MultiLevelMenu.Item name="" label="Promotions" icon={<PromotionalofferIcon />}>
      <MultiLevelMenu.Item name="promotionaloffers" to="./promotionaloffers" label="Promo Offers (List)" icon={<PromotionalofferIcon />} />
      <MultiLevelMenu.Item name="promotionalofferscalendar" to="./promotionalofferscalendar" label="Promo Offers (Calendar)" icon={<PromotionalofferIcon />} />
      <MultiLevelMenu.Item name="campaigncodes" to="./campaigncodes" label="Campaign Codes" icon={<CampaigncodeIcon />} />
      <MultiLevelMenu.Item name="campaigncoderedemptions" to="./campaigncoderedemptions" label="Code Redemptions" icon={<CampaigncoderedemptionIcon />} />
      {/* <MultiLevelMenu.Item name="welcomeoffers" to="./welcomeoffers" label="Welcome Offers" icon={<NewuserofferIcon />} /> */}
      {/* <MultiLevelMenu.Item name="smartfueloffers" to="./smartfueloffers" label="Smartfuel Offers" icon={<AasfofferIcon />} /> */}
    </MultiLevelMenu.Item>
    <MultiLevelMenu.Item name="" label="Communications" icon={<NotificationIcon />}>
      <MultiLevelMenu.Item name="notifications" to="./notifications" label="Notifications" icon={<NotificationIcon />} />
      <MultiLevelMenu.Item name="formsubmissions" to="./formsubmissions" label="Form Submissions" icon={<FormsubmissionIcon />} />
      <MultiLevelMenu.Item name="ratings" to="./ratings" label="Ratings" icon={<RatingIcon />} />
      <MultiLevelMenu.Item name="frequentlyaskedquestions" to="./frequentlyaskedquestions" label="FAQs" icon={<FrequentlyaskedquestionIcon />} />
    </MultiLevelMenu.Item>
    <MultiLevelMenu.Item name="" label="Fuel Shout!" icon={<AddCardIcon />}>
      <MultiLevelMenu.Item name="giftcards" to="./giftcards" label="Gift Cards" icon={<GiftcardIcon />} />
      <MultiLevelMenu.Item name="giftcardtransactions" to="./giftcardtransactions" label="Card Transactions" icon={<GiftcardtransactionIcon />} />
      <MultiLevelMenu.Item name="tranxactors" to="./tranxactors" label="Tranxactor Cards" icon={<TranxactorIcon />} />
    </MultiLevelMenu.Item>
    <MultiLevelMenu.Item name="" label="Admin" icon={<AdminPanelSettingsIcon />}>
      <MultiLevelMenu.Item name="fuelgrades" to="./fuelgrades" label="Fuel Grades" icon={<FuelgradeIcon />} />
      <MultiLevelMenu.Item name="services" to="./services" label="Services" icon={<ServiceIcon />} />
      <MultiLevelMenu.Item name="referralamounts" to="./referralamounts" label="Referral Amounts" icon={<ReferralamountIcon />} />
      <MultiLevelMenu.Item name="referrals" to="./referrals" label="Referrals" icon={<ReferralsIcon />} />
      <MultiLevelMenu.Item name="maintenancemodes" to="./maintenancemodes" label="Maintenance Mode" icon={<MaintenanceIcon />} />
    </MultiLevelMenu.Item>
  </MultiLevelMenu>
);

export const MyCustomLayout = props => (
  <AppLocationContext>
    <Layout {...props} menu={LeftHandSideMenu} />
  </AppLocationContext>
);
