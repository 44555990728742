import GiftcardIcon from '@mui/icons-material/CardGiftcard';
import GiftcardCreate from './GiftcardCreate';
import GiftcardEdit from './GiftcardEdit';
import GiftcardList from './GiftcardList';
import GiftcardShow from './GiftcardShow';

const Giftcards = {
    list: GiftcardList,
    create: GiftcardCreate,
    edit: GiftcardEdit,
    show: GiftcardShow,
    icon: GiftcardIcon,
};

export default Giftcards;
