import * as React from 'react';
import { ChipField, DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const PaymentcardTitle = () => {
  const record = useRecordContext();
  return <span>Payment card validation {record ? `"${record.transactionNumber}"` : ''}</span>;
};

export const PaymentcardvalidationShow = props => (
  <Show {...props} title={<PaymentcardTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="transactionNumber" />
      <DateField showTime source="transactionDateTime" options={{ timeZone: 'UTC' }} />
      <TextField source="responseText" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default PaymentcardvalidationShow;
