import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { DateField, Datagrid, EditButton, List, ShowButton, TextField, TextInput } from 'react-admin';

const useReferralamountListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const ReferralamountListActionToolbar = ({ children, ...props }) => {
  const classes = useReferralamountListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const ReferralamountFilters = [
  <TextInput source="amount" label="Search Referral amount" />,
];

export const ReferralamountList = () => (
  <List filters={ReferralamountFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <DateField showTime source="startDate" options={{ timeZone: 'UTC'}} />
      <DateField showTime source="endDate" options={{ timeZone: 'UTC'}} />
      <TextField source="amount" />
      <ReferralamountListActionToolbar>
          <EditButton />
          <ShowButton />
      </ReferralamountListActionToolbar>
    </Datagrid>
  </List>
);

export default ReferralamountList;
