import { ChipField, DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const RatingTitle = () => {
  const record = useRecordContext();
  return (
    <span>Rating {record ? `"${record.id}"` : ''}</span>
  );
};

export const RatingShow = props => (
  <Show {...props} title={<RatingTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="rating" />
      <TextField source="review" />
      <DateField showTime source="ratingSent" options={{ timeZone: 'UTC'}} />
      <ReferenceField label="Site" source="site_id" reference="sites">
        <ChipField source="siteName" />
      </ReferenceField>
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default RatingShow;
