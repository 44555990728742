import * as React from 'react';
import { BooleanField, NumberField, RichTextField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const FrequentlyaskedquestionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Frequently asked question {record ? `"${record.id}"` : ''}</span>
  );
};

export const FrequentlyaskedquestionShow = props => (
  <Show {...props} title={<FrequentlyaskedquestionTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="title" />
      <RichTextField source="body" />
      <BooleanField source="isHeading" looseValue />
      <NumberField source="parentId" />
      <NumberField source="ordering" />
    </SimpleShowLayout>
  </Show>
);

export default FrequentlyaskedquestionShow;
