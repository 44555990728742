import * as React from 'react';
import { BooleanField, ChipField, DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const GiftcardtransactionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Gift card transaction {record ? `"${record.transactionNumber}"` : ''}</span>
  );
};

export const GiftcardtransactionShow = props => (
  <Show {...props} title={<GiftcardtransactionTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="transactionNumber" />
      <DateField showTime source="transactionDateTime" options={{ timeZone: 'UTC'}} />
      <TextField source="paymentResult" />
      <TextField source="phone" />
      <TextField source="amount" />
      <TextField source="message" />
      <BooleanField looseValue source="isEmailSent" />
      <BooleanField looseValue source="isThanksSent" />
      <BooleanField looseValue source="isReferralRecipient" />
      <BooleanField looseValue source="isReferralReferrer" />
      <BooleanField looseValue source="isCampaign" />
      <BooleanField looseValue source="isGift" />
      <BooleanField looseValue source="isGiftClaimed" />
      <ReferenceField label="Gift card" source="giftcard_id" reference="giftcards">
        <ChipField source="tranxactorCard" />
      </ReferenceField>
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceField label="Payment card" source="paymentcard_id" reference="paymentcards">
        <ChipField source="cardNumber" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default GiftcardtransactionShow;
