import * as React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';

const FuelgradeCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="fuelGrade" />
      <TextInput source="colour" />
    </SimpleForm>
  </Create>
);

export default FuelgradeCreate;
