import ServiceIcon from '@mui/icons-material/RoomService';
import ServiceCreate from './ServiceCreate';
import ServiceEdit from './ServiceEdit';
import ServiceList from './ServiceList';
import ServiceShow from './ServiceShow';

const Services = {
    list: ServiceList,
    create: ServiceCreate,
    edit: ServiceEdit,
    show: ServiceShow,
    icon: ServiceIcon,
};

export default Services;
