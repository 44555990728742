import * as React from 'react';
import { AutocompleteInput, Create, DateInput, ReferenceInput, SimpleForm } from 'react-admin';

const CampaigncoderedemptionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="user_id" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput source="campaigncode_id" reference="campaigncodes">
        <AutocompleteInput optionText="code" />
      </ReferenceInput>
      <ReferenceInput source="giftcard_id" reference="giftcards">
        <AutocompleteInput optionText="tranxactorCard" />
      </ReferenceInput>
      <DateInput source="redeemedDateTime" label="Redeemed Date Time" />
    </SimpleForm>
  </Create>
);

export default CampaigncoderedemptionCreate;
