import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Children, cloneElement } from 'react';
import { BooleanField, Datagrid, EditButton, List, ShowButton } from 'react-admin';

const useMaintenancemodeListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const MaintenancemodeListActionToolbar = ({ children, ...props }) => {
  const classes = useMaintenancemodeListActionToolbarStyles();
  return <div className={classes.toolbar}>{Children.map(children, button => cloneElement(button, props))}</div>;
};

export const MaintenancemodeList = () => (
  <List>
    <Datagrid rowClick="show">
      <BooleanField source="isOn" looseValue />
      <MaintenancemodeListActionToolbar>
        <EditButton />
        <ShowButton />
      </MaintenancemodeListActionToolbar>
    </Datagrid>
  </List>
);

export default MaintenancemodeList;
