import GiftcardtransactionIcon from '@mui/icons-material/Receipt';
// import GiftcardtransactionCreate from './GiftcardtransactionCreate';
// import GiftcardtransactionEdit from './GiftcardtransactionEdit';
import GiftcardtransactionList from './GiftcardtransactionList';
import GiftcardtransactionShow from './GiftcardtransactionShow';

const Giftcardtransactions = {
  list: GiftcardtransactionList,
  // create: GiftcardtransactionCreate,
  // edit: GiftcardtransactionEdit,
  show: GiftcardtransactionShow,
  icon: GiftcardtransactionIcon,
};

export default Giftcardtransactions;
