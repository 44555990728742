import { ChipField, DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const NotificationTitle = () => {
  const record = useRecordContext();
  return (
    <span>Notification {record ? `"${record.notificationSent}"` : ''}</span>
  );
};

export const NotificationShow = props => (
  <Show {...props} title={<NotificationTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <DateField showTime source="notificationSent" options={{ timeZone: 'UTC'}} />
      <TextField source="notificationType" />
      <DateField showTime source="notificationRead" options={{ timeZone: 'UTC'}} />
      <ReferenceField label="Promotional offer" source="promotionaloffer_id" reference="promotionaloffers">
        <ChipField source="title" />
      </ReferenceField>
      <ReferenceField label="Transaction" source="transaction_id" reference="transactions">
        <ChipField source="transactionNumber" />
      </ReferenceField>
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default NotificationShow;
