import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { ChipField, DateField, Datagrid, EditButton, List, ReferenceField, ShowButton, TextField, TextInput } from 'react-admin';

const useCampaigncoderedemptionListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const CampaigncoderedemptionListActionToolbar = ({ children, ...props }) => {
  const classes = useCampaigncoderedemptionListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const CampaigncoderedemptionFilters = [
  <TextInput source="code" label="Search code" />,
];

export const CampaigncoderedemptionList = () => (
  <List filters={CampaigncoderedemptionFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceField label="Campaign Code" source="campaigncode_id" reference="campaigncodes">
        <ChipField source="code" />
      </ReferenceField>
      <ReferenceField label="Gift Card" source="giftcard_id" reference="giftcards">
        <ChipField source="tranxactorCard" />
      </ReferenceField>
      <DateField showTime source="redeemedDateTime" options={{ timeZone: 'UTC'}} />
      <CampaigncoderedemptionListActionToolbar>
          <EditButton />
          <ShowButton />
      </CampaigncoderedemptionListActionToolbar>
    </Datagrid>
  </List>
);

export default CampaigncoderedemptionList;
