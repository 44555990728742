import * as React from 'react';
import { BooleanInput, Create, ReferenceArrayInput, SelectArrayInput, SimpleForm, TextInput } from 'react-admin';

const SiteCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="siteName" />
      <TextInput source="siteType" />
      <TextInput source="latitude" />
      <TextInput source="longitude" />
      <TextInput source="address" />
      <TextInput source="phone" />
      <TextInput source="hours" />
      <BooleanInput source="is247" />
      <TextInput source="gst" />
      <ReferenceArrayInput source="fuelGrade_id" reference="fuelgrades">
        <SelectArrayInput label="Fuel grades" optionText="fuelGrade" source="fuelGrade_id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput source="service_id" reference="services">
        <SelectArrayInput label="Services" optionText="service" source="service_id" />
      </ReferenceArrayInput>
      <BooleanInput source="canPayInCar" />
      <TextInput source="invencoId" />
      <TextInput source="windcaveId" />
      <TextInput source="tranxactorId" />
      <TextInput source="triquestraId" />
      <TextInput source="aasfTerminalId" />
      <TextInput source="aasfSecret" />
    </SimpleForm>
  </Create>
);

export default SiteCreate;
