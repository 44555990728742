import * as React from 'react';
import { ChipField, DateField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const CampaigncoderedemptionTitle = () => {
  const record = useRecordContext();
  return (
    <span>Campaign code redemption {record ? `"${record.redeemedDateTime}"` : ''}</span>
  );
};

export const CampaigncoderedemptionShow = props => (
  <Show {...props} title={<CampaigncoderedemptionTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <ReferenceField label="User" source="user_id" reference="users">
        <ChipField source="email" />
      </ReferenceField>
      <ReferenceField label="Campaign Code" source="campaigncode_id" reference="campaigncodes">
        <ChipField source="code" />
      </ReferenceField>
      <ReferenceField label="Gift Card" source="giftcard_id" reference="giftcards">
        <ChipField source="tranxactorCard" />
      </ReferenceField>
      <DateField showTime source="redeemedDateTime" options={{ timeZone: 'UTC'}} />
    </SimpleShowLayout>
  </Show>
);

export default CampaigncoderedemptionShow;
