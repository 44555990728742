import * as React from 'react';
import { Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const FuelgradeTitle = () => {
  const record = useRecordContext();
  return (
    <span>Fuel grade {record ? `"${record.fuelGrade}"` : ''}</span>
  );
};

export const FuelgradeShow = props => (
  <Show {...props} title={<FuelgradeTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="fuelGrade" />
      <TextField source="colour" />
    </SimpleShowLayout>
  </Show>
);

export default FuelgradeShow;
