import * as React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import CardWithIcon from './CardWithIcon';

const DebugInvenco = () => {
  return (
    <CardWithIcon
      to="/debuginvenco"
      icon={CodeIcon}
      title="Debug"
      subtitle="Invenco"
    />
  );
};

export default DebugInvenco;
