import * as React from 'react';
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { buildAuthProvider } from 'react-admin-amplify';
import { myDataProvider } from './myDataProvider.js';
import { MyCustomLayout } from './MyCustomLayout.js';
import MyLoginPage from './myLoginPage.js';
import { Dashboard } from './dashboard.js';
import { theme } from './theme.js';
import awsExports from './aws-exports.js';
import users from './users';
import sites from './sites';
import fuelgrades from './fuelgrades';
import services from './services';
import paymentcards from './paymentcards';
import paymentcardvalidations from './paymentcardvalidations';
import transactions from './transactions';
import notifications from './notifications';
import promotionaloffers from './promotionaloffers';
import promotionalofferscalendar from './promotionalofferscalendar';
// import smartfueloffers from './smartfueloffers';
// import welcomeoffers from './welcomeoffers';
import formsubmissions from './formsubmissions';
import ratings from './ratings';
import giftcards from './giftcards';
import giftcardtransactions from './giftcardtransactions';
import tranxactors from './tranxactors';
import campaigncodes from './campaigncodes';
import campaigncoderedemptions from './campaigncoderedemptions';
import referralamounts from './referralamounts';
import maintenancemodes from './maintenancemodes';
import referrals from './referrals';
import frequentlyaskedquestions from './frequentlyaskedquestions';
// import DebugSmartfuelPage from './debug/DebugSmartfuel.js';
import DebugTranxactorPage from './debug/DebugTranxactor.js';
import DebugInvencoPage from './debug/DebugInvenco.js';

Amplify.configure(awsExports);

const App = () => (
  <Admin dashboard={Dashboard} loginPage={MyLoginPage} dataProvider={myDataProvider} authProvider={buildAuthProvider({ authGroups: ['admins'] })} theme={theme} layout={MyCustomLayout}>
    <Resource name="users" {...users} />
    <Resource name="transactions" {...transactions} />
    <Resource name="sites" {...sites} />
    <Resource name="promotionaloffers" options={{ label: 'Promo Offers (List)' }} {...promotionaloffers} />
    <Resource name="promotionalofferscalendar" options={{ label: 'Promo Offers (Calendar)' }} {...promotionalofferscalendar} />
    <Resource name="campaigncodes" options={{ label: 'Campaign Codes' }} {...campaigncodes} />
    <Resource name="campaigncoderedemptions" options={{ label: 'Campaign Code Redemptions' }} {...campaigncoderedemptions} />
    <Resource name="fuelgrades" options={{ label: 'Fuel Grades' }} {...fuelgrades} />
    <Resource name="services" {...services} />
    <Resource name="paymentcards" options={{ label: 'Payment Cards' }} {...paymentcards} />
    <Resource name="paymentcardvalidations" options={{ label: 'Payment Card Validations' }} {...paymentcardvalidations} />
    <Resource name="notifications" {...notifications} />
    {/* <Resource name="smartfueloffers" options={{ label: 'AA Smartfuel Offers' }} {...smartfueloffers} /> */}
    {/* <Resource name="welcomeoffers" options={{ label: 'Welcome Offers' }} {...welcomeoffers} /> */}
    <Resource name="formsubmissions" options={{ label: 'Form Submissions' }} {...formsubmissions} />
    <Resource name="ratings" {...ratings} />
    <Resource name="giftcards" options={{ label: 'Gift Cards' }} {...giftcards} />
    <Resource name="giftcardtransactions" options={{ label: 'Gift Card Transactions' }} {...giftcardtransactions} />
    <Resource name="tranxactors" options={{ label: 'Tranxactor Cards' }} {...tranxactors} />
    <Resource name="referralamounts" options={{ label: 'Referral Amounts' }} {...referralamounts} />
    <Resource name="maintenancemodes" options={{ label: 'Maintenance Mode' }} {...maintenancemodes} />
    <Resource name="referrals" {...referrals} />
    <Resource name="frequentlyaskedquestions" options={{ label: 'FAQs' }} {...frequentlyaskedquestions} />
    <CustomRoutes>
      {/* <Route path="/debugsmartfuel" element={<DebugSmartfuelPage />} /> */}
      <Route path="/debugtranxactor" element={<DebugTranxactorPage />} />
      <Route path="/debuginvenco" element={<DebugInvencoPage />} />
    </CustomRoutes>
  </Admin>
);

export default App;
