import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { BooleanField, BooleanInput, Datagrid, EditButton, List, NumberField, ShowButton, TextField, TextInput } from 'react-admin';

const useFrequentlyaskedquestionListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const FrequentlyaskedquestionListActionToolbar = ({ children, ...props }) => {
  const classes = useFrequentlyaskedquestionListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const frequentlyaskedquestionFilters = [
  <TextInput source="body" label="Search FAQ body" />,
  <BooleanInput source="isHeading" label="Is Heading?" />,
];

export const FrequentlyaskedquestionList = () => (
  <List filters={frequentlyaskedquestionFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="title" />
      <BooleanField source="isHeading" looseValue />
      <NumberField source="parentId" />
      <NumberField source="ordering" />
      <FrequentlyaskedquestionListActionToolbar>
          <EditButton />
          <ShowButton />
      </FrequentlyaskedquestionListActionToolbar>
    </Datagrid>
  </List>
);

export default FrequentlyaskedquestionList;
