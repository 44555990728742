import * as React from 'react';
import { Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const ServiceTitle = () => {
  const record = useRecordContext();
  return (
    <span>Service {record ? `"${record.service}"` : ''}</span>
  );
};

const ServiceEdit = props => (
  <Edit {...props} title={<ServiceTitle />}>
    <SimpleForm>
      <TextInput disabled label="ID" source="id" />
      <TextInput source="service" />
    </SimpleForm>
  </Edit>
);

export default ServiceEdit;
