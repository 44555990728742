import * as React from 'react';
import { Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const ServiceTitle = () => {
  const record = useRecordContext();
  return (
    <span>Service {record ? `"${record.service}"` : ''}</span>
  );
};

const ServiceShow = props => (
  <Show {...props} title={<ServiceTitle />}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <TextField source="service" />
    </SimpleShowLayout>
  </Show>
);

export default ServiceShow;
