import * as React from 'react';
import { DateInput, Edit, SimpleForm, TextInput, useRecordContext } from 'react-admin';

const ReferralamountTitle = () => {
  const record = useRecordContext();
  return (
    <span>Referral amount {record ? `"${record.id}"` : ''}</span>
  );
};

const ReferralamountEdit = props => (
  <Edit {...props} title={<ReferralamountTitle />}>
    <SimpleForm>
      <DateInput source="startDate" label="Start Date" />
      <DateInput source="endDate" label="End Date" />
      <TextInput source="amount" />
    </SimpleForm>
  </Edit>
);

export default ReferralamountEdit;
