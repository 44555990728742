import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { Children, cloneElement } from 'react';
import { Datagrid, EditButton, List, ShowButton, TextField, TextInput } from 'react-admin';

const useFuelgradeListActionToolbarStyles = makeStyles({
  toolbar: {
      alignItems: 'center',
      display: 'flex',
      marginTop: -1,
      marginBottom: -1,
  },
});

const FuelgradeListActionToolbar = ({ children, ...props }) => {
  const classes = useFuelgradeListActionToolbarStyles();
  return (
      <div className={classes.toolbar}>
          {
            Children.map(children, button => cloneElement(button, props))
          }
      </div>
  );
};

const fuelgradeFilters = [
  <TextInput source="siteName" label="Search Fuel grade" />,
];

export const FuelgradeList = () => (
  <List filters={fuelgradeFilters}>
    <Datagrid rowClick="show">
      <TextField label="ID" source="id" />
      <TextField source="fuelGrade" />
      <TextField source="colour" />
      <FuelgradeListActionToolbar>
          <EditButton />
          <ShowButton />
      </FuelgradeListActionToolbar>
    </Datagrid>
  </List>
);

export default FuelgradeList;
