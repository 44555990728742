import { makeStyles } from '@material-ui/core/styles';
import React, { Children, cloneElement } from 'react';
import { useMutation } from 'react-query';
import { BooleanField, BooleanInput, ChipField, Datagrid, EditButton, List, ReferenceArrayField, ShowButton, SingleFieldList, TextField, TextInput, Button, useDataProvider } from 'react-admin';

const useSiteListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1,
  },
});

const siteIntegrationStyles = makeStyles({
  button: {
    margin: '20px 0px 0px 0px',
    padding: '10px',
  },
  integrationcontainer: {
    width: '100%',
    textAlign: 'right',
  },
  integrationerror: {
    color: 'red',
    textAlign: 'right',
  },
});

const SiteListActionToolbar = ({ children, ...props }) => {
  const classes = useSiteListActionToolbarStyles();
  return <div className={classes.toolbar}>{Children.map(children, button => cloneElement(button, props))}</div>;
};

const SiteIntegrationButton = () => {
  const dataProvider = useDataProvider();
  const classes = siteIntegrationStyles();
  const { mutate, isLoading } = useMutation(
    ['sitesUpdater'],
    () => dataProvider.sitesUpdater()
  );
  const siteIntegrationLabel = isLoading ? 'Update in progress...' : 'Activate Sites Updater';

  return (
    <div className={classes.integrationcontainer}>
      <Button disabled={isLoading} onClick={() => mutate()} className={classes.button} color="primary" variant="outlined" size="small">
        {siteIntegrationLabel}
      </Button>
    </div>
  );
};

const siteFilters = [
  <TextInput source="siteName" label="Search Site name" />,
  <TextInput source="address" label="Search Address" />,
  <BooleanInput source="canPayInCar" label="Search Can pay in car" />,
];

const SiteList = () => {
  return (
    <div>
      <SiteIntegrationButton />
      <List filters={siteFilters}>
        <Datagrid rowClick="show">
          <TextField label="ID" source="id" />
          <TextField source="siteName" />
          <TextField source="address" />
          <ReferenceArrayField label="Fuel grades" reference="fuelgrades" source="fuelGrade_id">
            <SingleFieldList>
              <ChipField source="fuelGrade" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ReferenceArrayField label="Services" reference="services" source="service_id">
            <SingleFieldList>
              <ChipField source="service" />
            </SingleFieldList>
          </ReferenceArrayField>
          <BooleanField looseValue source="canPayInCar" />
          <SiteListActionToolbar>
            <EditButton />
            <ShowButton />
          </SiteListActionToolbar>
        </Datagrid>
      </List>
    </div>
  );
};

export default SiteList;
